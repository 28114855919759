<template>
  <div class="relative h-20 w-20 flex-shrink-0">
    <div
      class="absolute left-0 top-0 h-20 w-20 rounded-full bg-[#e6e9eb] p-2.5 dark:bg-[#767676]"
    >
      <div
        class="grid h-full w-full place-items-center rounded-full bg-white text-center dark:bg-[#292929]"
      >
        <div v-if="showProgress">
          <p class="text-[26px] font-bold leading-none dark:text-gray-100">
            {{ minutes }}
          </p>
          <p class="text-[11px] leading-none dark:text-gray-100">
            {{ minutesSpelling }}
          </p>
        </div>
        <img v-else src="~/assets/icons/hour-glasses.svg" alt="" />
      </div>
    </div>

    <svg
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
      class="relative z-20"
    >
      <defs>
        <mask :id="`mask-${id}`">
          <circle
            class="origin-center -rotate-90"
            cx="40"
            cy="40"
            :r="radius"
            stroke="white"
            stroke-width="10"
            fill="none"
            :stroke-dasharray="strokeDasharray"
            :stroke-dashoffset="strokeDashoffset"
          />
        </mask>
      </defs>
      <foreignObject
        x="0"
        y="0"
        width="80"
        height="80"
        :mask="`url(#mask-${id})`"
      >
        <div class="h-full w-full" :class="circleBackground"></div>
      </foreignObject>
    </svg>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  minutes: number
  progressPercent: number
  showProgress: boolean
}>()

const id = useId()
const isOverdue = computed(() => props.showProgress && props.minutes <= 0)

const radius = 35
const strokeDasharray = computed(() => 2 * Math.PI * radius)
const strokeDashoffset = computed(
  () => strokeDasharray.value * (1 - props.progressPercent / 100),
)

const circleBackground = computed(() => {
  if (!props.showProgress) return 'circle-bg-gray'
  if (isOverdue.value) return 'circle-bg-red'
  return 'circle-bg-gradient'
})

const minutesSpelling = computed(() => declination(props.minutes, 'минут|а|ы|'))
</script>

<style scoped>
.circle-bg-gradient {
  background: conic-gradient(
    from 0 at 50% 50%,
    #ff4d15 0,
    #ffc531 117deg,
    #fff85a 252deg,
    #40ad15 360deg
  );
}

.circle-bg-red {
  background: #ff4d15;
}

.circle-bg-gray {
  background: #e6e9eb;
}
</style>
